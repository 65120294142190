<template>
    <v-container grid-list-md pa-0 relative>
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card outlined class="primary--border">
                    <v-card-title class="title">
                        Student QR Logs
                        <v-spacer></v-spacer>
                        <add-button
                            permission="library-create"
                            @action="form.addQrLogDialog = true"
                        >
                        Add Qr Log
                        </add-button>
                    </v-card-title>
                    <v-card outlined>
                        <v-card-title outlined>
                            <v-flex xs3>
                                <v-calendar-field id-val="date" label="Date" v-model="attendance_date_filter">
                                </v-calendar-field>
                            </v-flex>

                            <v-flex xs3>

                                <VGradeField v-model="gradeId"></VGradeField>
                            </v-flex>

                            <v-flex xs3 sm3>
                                <v-select outlined dense :disabled="sections.length < 1" :items="sections" class="pa-0"
                                    label="Section" v-model="sectionId" />
                            </v-flex>
                            <v-flex xs2>
                                <search-button :permission="true" @action="get()">
                                    Search
                                </search-button>
                            </v-flex>
                        </v-card-title>
                        <v-data-table 
                            :headers="headers" 
                            :items="form.items.data" 
                            :options.sync="pagination"
                            :footer-props="footerProps" 
                            :server-items-length="form.items.meta.total"
                            :loading="form.loading">
                            <template v-slot:item="{ index, item }">
                                <tr>
                                    <td>{{ index + form.items.meta.from }}</td>
                                    <td class="text-xs-left">{{ item.name }}
                                        <div v-if="item.enroll_code">
                                            <span style="font-size: 10px; color: #666">(Enroll Code: {{ item.enroll_code
                                                }})</span>
                                        </div>
                                    </td>
                                    <td class="text-xs-left"> {{ item.attendance_date }}</td>
                                    <td class="text-xs-left"> {{ item.grade }} / {{ item.section || '' }}</td>
                                    <td class="text-xs-left"> {{ item.entry_at }} </td>
                                    <td class="text-xs-left"> {{ item.exit_at }} </td>
                                    <td class="text-xs-left"> 
                                        Entry By : {{ item.attendance_by_name }}  <br> 
                                        Exit By : {{ item.exit_by_name }}  <br> 
                                    
                                    </td>
                                    <td class="text-xs-left"> {{ item.status }} </td>
                                    <td class="text-xs-left">
                                        <v-tooltip bottom v-if="item.note && item.note.length > 10">
                                            <template v-slot:activator="{ on }">
                                                <span v-on="on"> {{ item.note.cleanHtml(10) }} </span>
                                            </template>
                                            <span>{{ item.note }}</span>
                                        </v-tooltip>
                                        <span v-else> {{ item.note }}</span>
                                    </td>

                                    <td class="text-right">
                                        <edit-button @agree="form.edit(item), selected_id = item.id" :permission=true />
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog v-model="form.dialog" persistent max-width="600px">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="title">Update</span>
                </v-card-title>
                <v-card-text class="pb-1 pt-4">
                    <v-form ref="form" :lazy-validation="lazy" @submit.prevent="save"
                        @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save">
                        <v-container grid-list-md>
                            <v-layout row wrap>
                                <v-flex xs6>
                                    <v-text-field outlined dense v-model="form.entry_at" label="Entry at" readonly
                                        class="pt-0" @click="timePickerModal = true, timePickerFor = 'entry'">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field outlined dense v-model="form.exit_at" label="Exit at" readonly
                                        class="pt-0" @click="timePickerModal = true, timePickerFor = 'exit'">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-textarea outlined name="Note" label="Note (optional)" v-model="form.note"
                                        required></v-textarea>
                                </v-flex>
                                
                            </v-layout>
                        </v-container>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="form.reset(), form.dialog = false">Close</v-btn>

                            <v-btn color="success" text @click="updateTime()">Update</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>

        <v-dialog ref="dialog" v-model="timePickerModal" :return-value.sync="form.entry_at"
            persistent width="290px">
            <v-time-picker :allowed-minutes="allowedMinutes"
                v-if="timePickerModal && timePickerFor === 'entry'" v-model="form.entry_at"
                full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timePickerModal = false, timePickerFor = ''">
                    Cancel
                </v-btn>
                <v-btn text color="primary"
                    @click="$refs.dialog.save(form.entry_at), timePickerFor = ''">
                    OK
                </v-btn>
            </v-time-picker>

            <v-time-picker :allowed-minutes="allowedMinutes"
                v-if="timePickerModal && timePickerFor === 'exit'" v-model="form.exit_at"
                full-width>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="timePickerModal = false, timePickerFor = ''">
                    Cancel
                </v-btn>
                <v-btn text color="primary"
                    @click="form.exit_at !== '' ? timePickerModal = false : '', timePickerFor = ''">
                    OK
                </v-btn>
            </v-time-picker>
        </v-dialog>
        <!-- ADD QR LOG DiALOG -->
        <v-dialog v-model="form.addQrLogDialog" persistent max-width="600px">
            <v-card>
                <v-card-title class="primary white--text">
                    <span class="title">Add</span>
                </v-card-title>
                <v-card-text class="pb-1 pt-4">
                    <v-form ref="form" v-model="valid" :lazy-validation="lazy" @submit.prevent="save" @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save">
                        <v-container grid-list-md>
                            <v-layout row wrap>
                               
                                <v-flex xs12>
                                    <v-student-search-field
                                        @onClear="onClear"
                                        @onSelectStudent="selectedStudentInfo"
                                    ></v-student-search-field>
                                </v-flex>
                                <v-flex xs4 style="margin-top: 20px;">
                                    <v-calendar-field id-val="attendance_date" label="Attendance date" v-model="form.attendance_date">
                                    </v-calendar-field>
                                </v-flex>
                                <v-flex xs4>
                                    <v-select
                                        :items="action_types"
                                        outlined
                                        dense
                                        class="pa-0"
                                        label="Action Type"
                                        name="action_type"
                                        v-model="form.action_type"
                                        :error-messages="form.errors.get('action_type')"
                                        :rules="[(v) => !!v || 'Action Type is required']"
                                    />
                                </v-flex>
                                <v-flex xs4>
                                    <v-select
                                        :items="log_status"
                                        outlined
                                        dense
                                        class="pa-0"
                                        label="Status"
                                        v-model="form.status"
                                    />
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field outlined dense v-model="form.entry_at" label="Entry at" readonly
                                        class="pt-0" @click="timePickerModal = true, timePickerFor = 'entry'">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs6>
                                    <v-text-field outlined dense v-model="form.exit_at" label="Exit at" readonly
                                        class="pt-0" @click="timePickerModal = true, timePickerFor = 'exit'">
                                    </v-text-field>
                                </v-flex>
                                <v-flex xs12>
                                    <v-textarea outlined name="Note" label="Note (optional)" v-model="form.note"
                                        required></v-textarea>
                                </v-flex>
                            </v-layout>
                        </v-container>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="warning" text @click="form.reset(), form.addQrLogDialog = false">Close</v-btn>

                            <v-btn color="success" text @click="save()">Save</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import Form from "@/library/Form";
import Mixins from "@/library/Mixins";

const NepaliDate = require("nepali-date-js");
const dt = new NepaliDate();
export default {
    mixins: [Mixins],

    data: () => ({
        gradeId: '',
        sectionId: '',
        sections: [],
        grades: [],
        saving: false,
        headers: [
            { text: "#", align: "left", value: "id", width: 50, sortable: false },
            { text: "Name", align: "left", value: "name", sortable: false },
            { text: "Attendance Date", align: "left", value: "attendance_date", sortable: false },
            { text: "Grade / Section", align: "left", value: "grade_section", sortable: false },
            { text: "Entry", align: "left", value: "entry_at", sortable: false },
            { text: "Exit", align: "left", value: "exit_at", sortable: false },
            { text: "Entry / Exit By", align: "left", value: "entry", sortable: false },

            { text: "Status", align: "left", value: "rank", sortable: false },
            { text: "Note", align: "left", value: "note", width: 110, sortable: false },
            { text: "Action", align: "right", sortable: false, width: 200 },
        ],
        action_types: [
            {text: 'Entry', value: 'entry'},
            {text: 'Exit', value: 'exit'},
        ],
        log_status: [
            {text: 'Present', value: 'present'},
            {text: 'Absent', value: 'absent'},
            {text: 'Leave', value: 'leave'},
            {text: 'Late', value: 'late'},
        ],
        attendance_date_filter: dt.format("YYYY-MM-DD"),

        form: new Form(
            {
                dialog: false,
                addQrLogDialog: false,
                enroll_code: '',
                enroll_id: '',
                grade_id: '',
                section_id: '',

                entry_at: '',
                exit_at: '',

                action_type: '',
                status:'',
                note: '',
                attendance_date: dt.format("YYYY-MM-DD"),


            },
            "/api/student-qr-log"
        ),
        valid: true,
        lazy: false,
        timePickerModal: false,
        timePickerFor: '',
        selected_id: '',
    }),
    computed: {
        ...mapState(['batch'])
    },

    watch: {
        gradeId: function () {
            this.getSections();
        },
    },

    mounted() {
    },

    methods: {
        allowedMinutes(v) {
            return v % 10 === 0;
        },
        getSections() {
            this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + this.gradeId).then(({ data }) => {
                this.sections = data.data.map(item => {
                    return { value: item.id, text: item.name }
                });
            })
        },
        get(params) {
            let extraparams =
                "gradeId=" + this.gradeId + "&sectionId=" + this.sectionId + "&attendance_date=" + this.attendance_date_filter
            let query = [null, undefined].includes(params)
                ? this.queryString(extraparams)
                : params;
            this.form.get(null, query).then(({ data }) => {
                this.pagination.totalItems = data.meta.total;
            });
        },
        updateTime() {
            this.form.put(this.selected_id)
                .then((res) => {
                    this.get();
                    this.form.reset();
                    this.selected_id = '';
                })
        },
        selectedStudentInfo(info){
            if(!info) return ''
            this.form.grade_id = info.details.academic.grade_id
            this.form.section_id = info.details.academic.section_id
            this.form.enroll_code = info.details.academic.enroll_code
            this.form.enroll_id = info.details.academic.enroll_id
            this.gradeId = info.details.academic.grade_id
        },
        onClear() {
            this.form.grade_id = '' 
            this.form.section_id = '' 
            this.form.enroll_code = '' 
            this.form.enroll_id = '' 
        },
        save(){
            if(this.form.enroll_code === '') {
                return this.$events.fire('notification', { message: 'Student not choosen', status: 'error' });
            }
            if (this.$refs.form.validate()) {
                this.form.fireFetch = false;
                this.form.post().then((res) => {
                    console.log(res)
                    this.$events.fire('notification', { message: 'QR Logs Created', status: 'success' });
                    this.gradeId = res.data.data.student_record.grade_id
                    this.form.grade_id = '' 
                    this.form.section_id = '' 
                    this.form.enroll_code = '' 
                    this.form.enroll_id = '' 
                    this.form.status = '';
                    this.form.action_type = '';
                    this.$refs.form.reset();
                    this.onClear();
                    setTimeout(() => {
                        this.get();
                    }, 200);

                })
                
            }
        }
    }


}
</script>